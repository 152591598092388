var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-card', [_c('b-overlay', [_c('b-row', [_vm.isAdminGudang && _vm.myGudang ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Lokasi Gudang: " + _vm._s(_vm.myGudang.nama_gudang))])]) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Pilih Barang")])]), _c('v-select', {
    attrs: {
      "options": _vm.barangs,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.barang_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "barang_id", $$v);
      },
      expression: "form.barang_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Tujuan Mutasi")])]), _c('v-select', {
    attrs: {
      "options": _vm.otherGudangs,
      "label": "text"
    },
    model: {
      value: _vm.form.tujuan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tujuan", $$v);
      },
      expression: "form.tujuan"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Daftar Stok Barang")]), _c('b-table', {
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.availableStocks
    },
    scopedSlots: _vm._u([{
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-button', {
          staticClass: "rounded-full",
          attrs: {
            "variant": "outline-info",
            "rounded": "",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.addBarang(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusCircleIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(barang)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('strong', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(lokasi)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.getLokasi(item)) + " ")];
      }
    }, {
      key: "cell(stok)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.stok)) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.satuan.satuan : "-") + " ")];
      }
    }, {
      key: "cell(nilai)",
      fn: function fn(_ref6) {
        var index = _ref6.index,
          item = _ref6.item;
        return [item.isEdit ? _c('b-form-input', {
          model: {
            value: item.nilai,
            callback: function callback($$v) {
              _vm.$set(item, "nilai", $$v);
            },
            expression: "item.nilai"
          }
        }) : _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.edit(index);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(id_satuan)",
      fn: function fn(_ref7) {
        var index = _ref7.index,
          item = _ref7.item;
        return [item.isEdit ? _c('v-select', {
          attrs: {
            "options": _vm.optkonversi,
            "label": "text"
          },
          model: {
            value: item.id_satuan,
            callback: function callback($$v) {
              _vm.$set(item, "id_satuan", $$v);
            },
            expression: "item.id_satuan"
          }
        }) : _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.edit(index);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref8) {
        var index = _ref8.index,
          item = _ref8.item;
        return [item.isEdit ? _c('b-form-input', {
          model: {
            value: item.jumlahEdit,
            callback: function callback($$v) {
              _vm.$set(item, "jumlahEdit", $$v);
            },
            expression: "item.jumlahEdit"
          }
        }) : _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.edit(index);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Daftar Mutasi")]), _c('b-table', {
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "fields": _vm.mutationFields,
      "items": _vm.mutations
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref9) {
        var index = _ref9.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(barang)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('strong', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(lokasi)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.getLokasi(item)) + " ")];
      }
    }, {
      key: "cell(tujuan)",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.form.tujuan ? _vm.form.tujuan.text : "-") + " ")];
      },
      proxy: true
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('strong', [_vm._v(_vm._s(item.barang ? item.barang.satuan.satuan : "-"))])];
      }
    }, {
      key: "cell(satuan2)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('strong', [_vm._v(_vm._s(item.barang ? item.barang.satuan.satuan : "-"))])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref14) {
        var index = _ref14.index;
        return [_c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.mutations.splice(index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1)];
      }
    }])
  })], 1)], 1), _c('section', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.isIncompletedForm,
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan Mutasi ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }